<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else>
    <ion-item class="ion-no-padding">
      <ion-label position="stacked">{{ $t('deviceGroup.Singular') }}</ion-label>
      <ion-select v-model="form.groupUuid"
                  :placeholder="$t('device.Group placeholder')"
                  interface="alert"
                  @ionChange="storeChanges"
                  :interfaceOptions="{cssClass: 'alert-room-select' }">
        <ion-select-option value="">[ {{ $t('device.Unassigned') }} ]</ion-select-option>
        <ion-select-option v-for="group in deviceGroups"
                           :key="group.uuid"
                           :value="group.uuid"
                           :disabled="group.notAssignable">
          {{ group.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item class="ion-no-padding ion-margin-top">
      <ion-label position="stacked">{{ $t('device.Name') }}</ion-label>
      <ion-input class="input-devicegroup"
                 v-model="form.name"
                 :maxlength="20"
                 @ionBlur="storeChanges"
                 :placeholder="$t('device.Name placeholder')" />
    </ion-item>
  </div>
</template>

<script>
import get from 'get-value'
import { computed } from 'vue'
import { GrosensComponents } from '~gro-plugins'
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { deviceQueries, deviceMutations } from '~gro-modules/Device'
import { formCopy } from '~gro-helpers'
import { growerTypes } from '~gro-modules/Bridge'
import { CLIMATE_ZONE } from '~gro-modules/Sensor'
import { getZoneForType } from '~gro-modules/Device/types'
import { DRYING, PROPAGATION } from '~gro-modules/DeviceGroup/groupTypes'

export default {
  props: {
    autoSave: {
      type: Boolean,
      default: false,
    },
    serialNumber: {
      type: String,
    },
  },
  emits: ['update:device'],
  setup (props, { emit }) {
    const { groups } = deviceGroupQueries.getAll('uuid', 'name', 'type')
    const { device, loading, onResult } = deviceQueries.get(props.serialNumber)
    const form = formCopy(onResult, () => {
      emit('update:device', device)
      return {
        groupUuid: device.value.group ? device.value.group.uuid : null,
        name: device.value.name,
        description: device.value.description,
      }
    })

    const deviceGroups = computed(() => {
      if (GrosensComponents.growerType === growerTypes.VEG) {
        return groups.value
      }
      if (getZoneForType(device.value.type) === CLIMATE_ZONE) {
        return groups.value
      }
      return groups.value.map(group => ({
        notAssignable: [
          PROPAGATION,
          DRYING,
        ].includes(group.type),
        ...group,
      }))
    })

    return {
      device,
      deviceGroups,
      loading,
      form,
    }
  },
  methods: {
    async storeChanges () {
      if (this.autoSave) return this.submit()
    },
    async submit () {
      let result

      if (this.form.groupUuid !== get(this.device, 'group.uuid', { default: null })) {
        result = await deviceMutations.assignToGroup(this.serialNumber, this.form.groupUuid)
        if (result.errors) {
          await this.$showGraphQLErrors(result.errors)
          return false
        }
      }

      result = await deviceMutations.setOptionalInfo(this.serialNumber, this.form)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackChanged()
      return true
    },
    async deleteDevice () {
      if (!await this.$confirm('device.Delete confirm')) return false
      const result = await deviceMutations.deleteDevice(this.device.serialNumber)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackDeleted()
      return true
    },
    trackChanged () {
      const group = this.deviceGroups.find(group => group.uuid === this.form.groupUuid)
      this.$track('DeviceChanged', {
        serialNumber: this.serialNumber,
        type: this.device.type,
        groupUuid: group ? group.uuid : null,
        groupName: group ? group.name : null,
        groupType: group ? group.type : null,
      })
    },
    trackDeleted () {
      this.$track('DeviceGroupDeleted', {
        serialNumber: this.device.serialNumber,
        type: this.device.type,
      })
    },
  },
}
</script>
