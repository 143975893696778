<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else>
    <ion-item class="ion-no-padding">
      <ion-label position="stacked">{{ $t('deviceGroup.Type') }}</ion-label>
      <ion-select v-model="form.type"
                  :disabled="managedByEgro"
                  @ionChange="generateName"
                  :placeholder="$t('deviceGroup.Type placeholder')">
        <ion-select-option v-for="type in groupTypes"
                           :key="type.type"
                           :value="type.type"
                           :disabled="type.notAssignable">
          {{ $t(`deviceGroup.types.${type.type}`) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item class="ion-no-padding ion-margin-top">
      <ion-label position="stacked">{{ $t('deviceGroup.Name') }}</ion-label>
      <ion-input v-model="form.name"
                 @ionBlur="storeChanges"
                 class="input-devicegroup"
                 :maxlength="20"
                 :disabled="generatingName || managedByEgro" />
    </ion-item>
    <ion-item class="ion-no-padding ion-margin-top">
      <ion-label position="stacked">{{ $t('deviceGroup.Growing medium') }}</ion-label>
      <ion-select v-model="form.growingMedium"
                  @ionChange="storeChanges"
                  :interfaceOptions="{cssClass: 'alert-slab-select' }"
                  :placeholder="$t('deviceGroup.Growing medium placeholder')">
        <ion-select-option v-for="medium in growingMedia" :key="medium" :value="medium">
          {{ $t(`deviceGroup.growing media.${medium}`) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item v-if="form.growingMedium !== growingMediumGrodan" lines="none" class="medium-alert">
      {{ $t('deviceGroup.Growing medium warning') }}
    </ion-item>
    <ion-item class="ion-no-padding ion-margin-top">
      <ion-label position="stacked">{{ $t('deviceGroup.Slab type') }}</ion-label>
      <ion-select v-model="form.slabType"
                  @ionChange="storeChanges"
                  :interfaceOptions="{cssClass: 'alert-slab-select' }"
                  :placeholder="$t('deviceGroup.Slab type placeholder')">
        <ion-select-option v-for="type in slabTypes" :key="type" :value="type">
          {{ $t(`deviceGroup.slab types.${type}`) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </div>
</template>

<script>
import { egroQueries } from '~gro-modules/Egro'
import { deviceGroupMutations, deviceGroupQueries, slabTypes, groupTypes, growingMedia } from '~gro-modules/DeviceGroup'
import { fallbackSlabType } from '~gro-modules/DeviceGroup/slabTypes'
import { growingMediumGrodan } from '~gro-modules/DeviceGroup/growingMedia'
import { deviceMutations, deviceQueries } from '~gro-modules/Device'
import { formCopy } from '~gro-helpers'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { growerTypes } from '~gro-modules/Bridge'
import { getZoneForType } from '~gro-modules/Device/types'
import { CLIMATE_ZONE } from '~gro-modules/Sensor'
import { DRYING, PROPAGATION } from '~gro-modules/DeviceGroup/groupTypes'

export default {
  props: {
    autoGenerateName: {
      type: Boolean,
    },
    autoSave: {
      type: Boolean,
      default: false,
    },
    deviceGroup: {
      type: Object,
      default: () => ({}),
    },
    uuid: {
      type: String,
    },
  },
  emits: ['update:group'],
  setup (props, { emit }) {
    if (props.deviceGroup.uuid) {
      return {
        loading: false,
        group: props.deviceGroup,
        form: { ...props.deviceGroup },
        slabTypes,
        growingMedia,
        growingMediumGrodan,
        assignableDevice: null,
      }
    }
    if (!props.uuid) {
      let assignableDevice = null
      let loading = false
      const assignableSerialNumber = useRoute().query.assignDevice
      if (assignableSerialNumber) {
        const deviceGet = deviceQueries.get(assignableSerialNumber)
        assignableDevice = deviceGet.device
        loading = deviceGet.loading
      }

      return {
        loading,
        group: {},
        form: ref({
          uuid: null,
          type: null,
          slabType: null,
          growingMedium: growingMediumGrodan,
          name: '',
          description: '',
        }),
        assignableDevice,
        slabTypes,
        growingMedia,
        growingMediumGrodan,
      }
    }

    const { loading, group, onResult } = deviceGroupQueries.getWithDevices(props.uuid)
    const form = formCopy(onResult, () => {
      emit('update:group', group)
      return {
        uuid: group.value.uuid || null,
        type: group.value.type || groupTypes[0],
        slabType: group.value.slabType || slabTypes[0],
        growingMedium: group.value.slabType || growingMedia[0],
        name: group.value.name || '',
        description: group.value.description || '',
      }
    })

    return {
      loading,
      group,
      form,
      slabTypes,
      growingMedia,
      growingMediumGrodan,
      assignableDevice: null,
    }
  },
  data () {
    return {
      generatingName: false,
      managedByEgro: false,
    }
  },
  computed: {
    groupTypes () {
      if (!this.$growerType) {
        return []
      }
      if (this.$growerType === growerTypes.VEG ||
        !this.assignableDevice ||
        getZoneForType(this.assignableDevice.type) === CLIMATE_ZONE) {
        return groupTypes[this.$growerType].map(type => ({ type }))
      }
      return groupTypes[this.$growerType].map(type => ({
        type,
        notAssignable: [
          PROPAGATION,
          DRYING,
        ].includes(type),
      }))
    },
  },
  methods: {
    async generateName () {
      if (this.form.uuid) return this.storeChanges()
      if (!this.form.type) return
      this.generatingName = true
      const base = this.$t(`deviceGroup.types.${this.form.type}`)
      let count = 0
      try {
        count = await deviceGroupQueries.asyncDeviceGroupTypeCount(this.form.type)
      } finally {
        this.generatingName = false
      }
      this.form.name = `${base} #${count + 1}`
      return this.storeChanges()
    },
    async storeChanges () {
      this.$forceUpdate()
      if (this.autoSave) return this.submit()
    },
    async submit () {
      const form = { ...this.form }
      if (this.managedByEgro) {
        delete form.name
        delete form.type
      }

      if (!this.validateForm(form)) {
        return false
      }

      let result
      let uuid
      if (this.group.uuid) {
        result = await deviceGroupMutations.updateDeviceGroup(form)
        uuid = this.group.uuid
        this.trackChanged(uuid)
      } else {
        result = await deviceGroupMutations.createDeviceGroup(form)
        uuid = result.data.deviceCreateDeviceGroup
        if (this.assignableDevice) {
          await this.assignDevice(uuid)
        }
        this.trackCreated(uuid, this.assignableDevice?.serialNumber)
      }

      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }

      return {
        ...this.form,
        uuid,
      }
    },
    async assignDevice (uuid) {
      const result = await deviceMutations.assignToGroup(this.assignableDevice.serialNumber, uuid)
      if (result.errors) await this.$showGraphQLErrors(result.errors)
    },
    async unassignDevices () {
      if (!this.group.devices.length) {
        await this.$alert('deviceGroup.Unassign unable')
        return false
      }
      if (!await this.$confirm('deviceGroup.Unassign confirm', { count: this.group.devices.length })) {
        return false
      }

      let errors = 0
      Promise.all(this.group.devices.map(async (device) => {
        try {
          await deviceMutations.assignToGroup(device.serialNumber, null)
        } catch (e) {
          errors++
        }
      }))
      if (errors) {
        await this.$alert('deviceGroup.Unassign failure')
        return false
      }
      this.trackUnassigned()
      return true
    },
    async deleteGroup () {
      if (this.group.devices.length) {
        await this.$alert('deviceGroup.Delete unable')
        return false
      }
      if (!await this.$confirm('deviceGroup.Delete confirm')) return false

      const result = await deviceGroupMutations.deleteDeviceGroup(this.group.uuid)

      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackDeleted()
      return true
    },
    validateForm (form) {
      if (form.growingMedium == null) {
        this.$toast('deviceGroup.Growing medium placeholder')
        return false
      } else if (form.growingMedium !== growingMediumGrodan) {
        form.slabType = fallbackSlabType
      }

      if (form.slabType == null) {
        this.$toast('deviceGroup.Slab type placeholder')
        return false
      }

      return true
    },
    trackCreated (uuid, serialNumber = null) {
      this.$track('DeviceGroupAdded', {
        uuid,
        name: this.form.name,
        type: this.form.type,
        slabType: this.form.slabType,
        withDeviceSerialNumber: serialNumber,
      })
    },
    trackChanged (uuid) {
      this.$track('DeviceGroupChanged', {
        uuid,
        name: this.form.name,
        type: this.form.type,
        slabType: this.form.slabType,
      })
    },
    trackDeleted () {
      this.$track('DeviceGroupDeleted', {
        uuid: this.form.uuid,
        name: this.form.name,
        type: this.form.type,
      })
    },
    trackUnassigned () {
      this.$track('DeviceGroupUnassignedDevices', {
        uuid: this.form.uuid,
        name: this.form.name,
        type: this.form.type,
      })
    },
  },
  async mounted () {
    this.managedByEgro = this.$growerType !== 'VEG' && await egroQueries.isConnected()
    if (this.managedByEgro) return
    if (!this.form.name) await this.generateName()
  },
}
</script>
<style lang="scss" scoped>
.medium-alert {
  color: var(--ion-color-danger);
  font-size: 12px;
  font-style: italic;
}
</style>
