<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else>
    <ion-item class="padding ion-margin-top">
      <ion-label position="stacked">{{ $t('settings.smartbox.IP address') }}</ion-label>
      <ion-input readonly
                 v-model="form.ipAddress"
                 class="input-devicegroup" />
    </ion-item>
    <ion-item class="padding ion-margin-top">
      <ion-label position="stacked">{{ $t('settings.smartbox.Name') }}</ion-label>
      <ion-input v-model=form.name
                 class="input-devicegroup"
                 :maxlength="20" />
    </ion-item>
      <ion-item class="padding ion-margin-top">
      <ion-label position="stacked">{{ $t('settings.smartbox.Serial number') }}</ion-label>
      <ion-input readonly
                 v-model=smartbox.serialNumber
                 class="input-devicegroup" />
    </ion-item>
  </div>
</template>

<script>
import { formCopy } from '~gro-helpers'
import { watch } from 'vue'
import { smartboxMutations, smartboxQueries } from '~gro-modules/Smartbox'

export default {
  props: ['hardwareAddress'],
  emits: ['update:smartbox', 'update:loading'],
  setup (props, { emit }) {
    const { smartbox, loading, onResult, refetch } = smartboxQueries.getSmartbox(props.hardwareAddress)
    const form = formCopy(onResult, () => {
      emit('update:smartbox', smartbox)
      return {
        name: smartbox.value.name,
        hardwareAddress: smartbox.value.hardwareAddress,
        ipAddress: smartbox.value.ipAddress,
      }
    })

    watch(loading, (value) => {
      emit('update:loading', value)
    }, { immediate: true })

    return {
      smartbox,
      loading,
      refetch,
      form,
    }
  },
  methods: {
    async submit () {
      const result = await smartboxMutations.updateSmartbox(this.form)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackChanged()
      this.refetch()
      return true
    },
    trackChanged () {
      this.$track('SmartboxChanged', this.form)
    },
  },
}
</script>
