<template>
  <ion-grid class="full-height" v-if="loading && !isRefreshingIp">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else class="inner-settings">
    <ion-list class="ion-padding-top" lines="full">
      <ion-item>
        <ion-label position="stacked">{{ $t('settings.gateway.Name') }}</ion-label>
        <ion-input class="input-devicegroup"
                   v-model="form.name"
                   :maxlength="20" />
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('settings.gateway.Description') }}</ion-label>
        <ion-input class="input-devicegroup"
                   v-model="form.description" />
      </ion-item>
      <ion-item>
        <ion-label>{{ $t('settings.gateway.Hardware address') }}</ion-label>
        <ion-label>{{ gateway.hardwareAddress }}</ion-label>
      </ion-item>
      <ion-item v-if="gateway.model">
        <ion-label>{{ $t('settings.gateway.Model') }}</ion-label>
        <ion-label>{{ gateway.model }}</ion-label>
      </ion-item>
      <ion-item v-if="isOnsiteGateway">
        <ion-label>{{ $t('settings.gateway.Ip address') }}</ion-label>
        <ion-label>
          {{ gateway.ipAddress }}
          <ion-button v-if="isOnsiteGateway"
                      color="cto"
                      fill="clear"
                      class="ion-float-right device-settings-button"
                      @click="refreshIpAddress">
            <gro-spinner v-if="isRefreshingIp" />
            <ion-icon v-else name="refresh" />
          </ion-button>
        </ion-label>
      </ion-item>
      <ion-item v-if="isOnsiteGateway">
        <ion-label>{{ $t('settings.gateway.Firmware') }}</ion-label>
        <ion-label>{{ shortFirmwareVersion }}</ion-label>
      </ion-item>
      <template v-if="isOnsiteGateway">
        <ion-item v-if="gateway.installStatus === INSTALLED">
          <ion-label>{{ $t('settings.gateway.Last seen') }}</ion-label>
          <ion-label class="ion-text-wrap">
            <connection-last-seen
              @update:hasProblem="$emit('update:hasConnectionProblem', $event)"
              :lastSeen="gateway.lastSeen"
              :createdAt="gateway.installedAt"
              :hasData="!!gateway.lastSeen"
              :firstConnectionMinutes="2" details />
          </ion-label>
        </ion-item>
        <ion-item v-else>
          <ion-label>{{ $t('settings.gateway.Install status') }}</ion-label>
          <ion-label class="ion-text-wrap">
            <ion-label> {{ $t(`gateway.install status.${gateway.installStatus}`) }}</ion-label>
          </ion-label>
        </ion-item>
      </template>
      <template v-else>
        <ion-item>
          <ion-label>{{ $t('settings.gateway.Last seen') }}</ion-label>
          <ion-label class="ion-text-wrap">
            <connection-last-seen
              :lastSeen="gateway.lastSeen"
              :createdAt="gateway.createdAt"
              :hasData="!!gateway.lastSeen"
              :firstConnectionMinutes="2" details />
          </ion-label>
        </ion-item>
      </template>
    </ion-list>
  </div>
</template>

<script>
import { formCopy } from '~gro-helpers'
import { computed, ref, watch } from 'vue'
import { gatewayMutations, gatewayQueries } from '~gro-modules/Gateway'
import installStatus from '~gro-modules/Gateway/installStatus'
import { GATEWAY_MODE_ONSITE } from '~gro-modules/Gateway/modes'

export default {
  emits: ['update:gateway', 'update:loading', 'update:hasConnectionProblem'],
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const { gateway, loading, onResult, refetch } = gatewayQueries.getGateway(props.hardwareAddress, 10 * 1000)
    const shortFirmwareVersion = computed(() => {
      return gateway.value?.firmwareVersion?.substring(0, 5) || '-'
    })
    const form = formCopy(onResult, () => {
      emit('update:gateway', gateway.value)
      return {
        name: gateway.value.name,
        description: gateway.value.description,
        hardwareAddress: props.hardwareAddress,
      }
    })

    const isOnsiteGateway = computed(() => {
      return gateway.value.mode === GATEWAY_MODE_ONSITE
    })

    watch(loading, (value) => {
      emit('update:loading', value)
    }, { immediate: true })

    return {
      INSTALLED: installStatus.INSTALLED,
      isRefreshingIp: ref(false),
      isOnsiteGateway,
      gateway,
      shortFirmwareVersion,
      loading,
      form,
      refetch,
    }
  },
  methods: {
    async submit () {
      this.$emit('update:loading', true)
      const result = await gatewayMutations.updateGateway(this.form)
      this.$emit('update:loading', false)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      return true
    },
    async deleteGateway () {
      if (!await this.$confirm('settings.gateway.Delete confirm')) {
        return false
      }
      const result = await gatewayMutations.deleteGateway(this.hardwareAddress)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      return true
    },
    async refreshIpAddress () {
      if (this.isRefreshingIp) {
        return
      }
      this.isRefreshingIp = true
      if (!await gatewayMutations.refreshGatewayIpAddress(this.hardwareAddress)) {
        this.$showError(this.$t('settings.gateway.Ip refresh error'))
      } else {
        await this.refetch()
      }
      this.isRefreshingIp = false
    },
  },
}
</script>
