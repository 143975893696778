export default {
  props: {
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    autoSubmit (methodName = null) {
      if (!this.autoSave) return
      methodName = typeof methodName === 'string' ? methodName : 'submit'
      this[methodName]()
    },
  },
}
