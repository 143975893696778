<template>
  <ion-slides v-if="type === RAK7240"
              pager
              class="full-height"
              :options="slideOpts"
              @ionSlideReachEnd="lastSlide = true"
              @ionSlideReachStart="onFirstSlide = true"
              ref="innerSlider">
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height full-width">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7240/gateway_setup_antenna.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK.Antenna') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK.AntennaSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-80vw" src="/images/gateways/RAK7240/gateway_setup_ethernet.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK.Ethernet') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK.EthernetSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7240/gateway_setup_power.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK.Power') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK.PowerSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7240/gateway_setup_placement.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK.Location') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK.LocationSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide locked>
      <ion-grid class="full-height scrollable">
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <div>
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK.Mounting') }}</h1>
          </div>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_SQUARE)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_beam.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK.Mounting square') }}
          </gro-button>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_ROUND)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_pole.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK.Mounting round') }}
          </gro-button>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_WALL)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_wall.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK.Mounting wall') }}
          </gro-button>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div v-if="mountingType === MOUNTING_WALL">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_wall_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK.Mounting wall') }}
            </h1>
          </div>
          <div v-else-if="mountingType === MOUNTING_ROUND">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_pole_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK.Mounting round') }}
            </h1>
          </div>
          <div v-else-if="mountingType === MOUNTING_SQUARE">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7240/gateway_setup_mount_beam_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK.Mounting square') }}
            </h1>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
  </ion-slides>
  <ion-slides v-else-if="type === LAIRD_RG1XX"
              pager
              class="full-height"
              :options="slideOpts"
              @ionSlideReachEnd="lastSlide = true"
              @ionSlideReachStart="onFirstSlide = true"
              ref="innerSlider">
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg src="/images/gateways/LAIRD_RG1XX/gateway_setup_antenna.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.LAIRD.Antenna') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.LAIRD.AntennaSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg src="/images/gateways/LAIRD_RG1XX/gateway_setup_ethernet.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.LAIRD.Ethernet') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.LAIRD.EthernetSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg src="/images/gateways/LAIRD_RG1XX/gateway_setup_power.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.LAIRD.Power') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.LAIRD.EthernetSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg src="/images/gateways/LAIRD_RG1XX/gateway_setup_placement.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.LAIRD.Location') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.LAIRD.LocationSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
  </ion-slides>
  <ion-slides v-else-if="type === RAK7289"
              pager
              class="full-height"
              :options="slideOpts"
              @ionSlideReachEnd="lastSlide = true"
              @ionSlideReachStart="onFirstSlide = true"
              ref="innerSlider">
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height  full-width">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7289/gateway_setup_antenna.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK7289.Antenna') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK7289.AntennaSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-80vw" src="/images/gateways/RAK7289/gateway_setup_ethernet.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK7289.Ethernet') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK7289.EthernetSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7289/gateway_setup_power.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK7289.Power') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK7289.PowerSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div>
            <animated-svg class="width-90vw" src="/images/gateways/RAK7289/gateway_setup_placement.svg" />
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK7289.Location') }}</h1>
            <ion-text color="medium">
              <p>{{ $t('onboarding.gateway.explain.RAK7289.LocationSub') }}</p>
            </ion-text>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide locked>
      <ion-grid class="full-height scrollable">
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <div>
            <h1 class="ion-text-center">{{ $t('onboarding.gateway.explain.RAK7289.Mounting') }}</h1>
          </div>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_SQUARE)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_beam.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK7289.Mounting square') }}
          </gro-button>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_ROUND)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_pole.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK7289.Mounting round') }}
          </gro-button>
        </ion-row>
        <ion-row class="ion-padding ion-justify-content-center" @click="selectMountingType(MOUNTING_WALL)">
          <img class="img-mount-small" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_wall.png')" />
          <gro-button fill="clear" class="full-width">
            {{ $t('onboarding.gateway.explain.RAK7289.Mounting wall') }}
          </gro-button>
        </ion-row>
      </ion-grid>
    </ion-slide>
    <ion-slide>
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center ion-justify-content-center full-height">
          <div v-if="mountingType === MOUNTING_WALL">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_wall_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK7289.Mounting wall') }}
            </h1>
          </div>
          <div v-else-if="mountingType === MOUNTING_ROUND">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_pole_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK7289.Mounting round') }}
            </h1>
          </div>
          <div v-else-if="mountingType === MOUNTING_SQUARE">
            <img class="img-mount" :src="$asset('/images/gateways/RAK7289/gateway_setup_mount_beam_detail.svg')" />
            <h1 class="ion-text-center">
              {{ $t('onboarding.gateway.explain.RAK7289.Mounting square') }}
            </h1>
          </div>
        </ion-row>
      </ion-grid>
    </ion-slide>
  </ion-slides>
</template>

<script>
import gatewayTypes, { LAIRD_RG1XX, RAK7240, RAK7289 } from '~gro-modules/Gateway/types'
import { ref } from 'vue'

const MOUNTING_WALL = 'wall'
const MOUNTING_ROUND = 'round'
const MOUNTING_SQUARE = 'square'
export default {
  emits: ['finished'],
  props: {
    type: {
      required: true,
      validator (value) {
        return gatewayTypes.includes(value)
      },
    },
  },
  setup () {
    return {
      innerSlider: ref(null),
      lastSlide: ref(false),
      onFirstSlide: ref(false),
      locked: ref(false),
      slideOpts: {
        initialSlide: 0,
        speed: 400,
        allowTouchMove: false,
      },
      mountingType: ref(null),
      LAIRD_RG1XX,
      RAK7240,
      RAK7289,
      MOUNTING_WALL,
      MOUNTING_ROUND,
      MOUNTING_SQUARE,
    }
  },
  methods: {
    selectMountingType (type) {
      this.mountingType = type
      this.$refs.innerSlider.slideNext()
      this.locked = false
    },
    async nextSlide () {
      this.onFirstSlide = false
      if (this.lastSlide) {
        this.$emit('finished')
      } else {
        await this.$refs.innerSlider.slideNext()
        this.locked = !!this.$refs.innerSlider.querySelector('.swiper-slide-active[locked]')
      }
    },
    async previousSlide () {
      await this.$refs.innerSlider.slidePrev()
      this.locked = !!this.$refs.innerSlider.querySelector('.swiper-slide-active[locked]')
    },
  },
}
</script>

<style lang="scss" scoped>
.image-gateway {
  height: 20vh;
}

.img-mount-small {
  max-height: 15vh;
  margin: auto;
}

.img-mount {
  width: 90vw !important;
  height: auto !important;
}
</style>
