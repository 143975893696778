<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else>
    <ion-item>
      <ion-label position="stacked">{{ $t('convertor.Name') }}</ion-label>
      <ion-input class="input-devicegroup"
                 v-model="form.name" />
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ $t('deviceGroup.Singular') }}</ion-label>
      <ion-select v-model="form.deviceGroupUuid"
                  :placeholder="$t('convertor.Group placeholder')"
                  interface="alert"
                  :interfaceOptions="{cssClass: 'alert-room-select' }">
        <ion-select-option v-for="group in groups" :key="group.uuid" :value="group.uuid">
          {{ group.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item v-if="!form.deviceGroupUuid" lines="none" class="group-binding-alert">
      {{ $t('convertor.Not connected to group') }}
    </ion-item>
  </div>
</template>

<script>
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { convertorQueries, convertorMutations } from '~gro-modules/Convertor'
import { formCopy } from '~gro-helpers'
import { watch } from 'vue'

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  emits: ['update:converter', 'update:loading'],
  setup (props, { emit }) {
    const { groups } = deviceGroupQueries.getAll('uuid', 'name')
    const { virtualConverter, loading, onResult, refetch } = convertorQueries.getVirtualConverter(props.uuid, 5 * 1000)
    const form = formCopy(onResult, () => {
      emit('update:converter', virtualConverter)
      return {
        uuid: props.uuid,
        name: virtualConverter.value.name,
        deviceGroupUuid: virtualConverter.value.deviceGroup ? virtualConverter.value.deviceGroup.uuid : null,
      }
    })

    watch(loading, (value) => {
      emit('update:loading', value)
    }, { immediate: true })

    return {
      virtualConverter,
      groups,
      loading,
      refetch,
      form,
    }
  },
  methods: {
    async submit () {
      const result = await convertorMutations.updateVirtualConverter(this.form)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackChanged()
      this.refetch()
      return true
    },
    async deleteConverter () {
      if (!await this.$confirm('convertor.Delete confirm')) return false
      const result = await convertorMutations.deleteVirtualConverter(this.uuid)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackDeleted()
      return true
    },
    trackChanged () {
      this.$track('VirtualConverterChanged', this.form)
    },
    trackDeleted () {
      this.$track('VirtualConverterDeleted', {
        hardwareAddress: this.hardwareAddress,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.group-binding-alert {
  color: var(--ion-color-danger);
  font-size: 12px;
  font-style: italic;
}
</style>
