<template>
  <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center ion-padding-bottom">
    <h1 class="ion-text-center">{{ $t('settings.gateway.install.type.Select') }}</h1>
  </ion-row>
  <ion-row class="ion-padding ion-justify-content-center" @click="$emit('select',RAK7240)">
    <img :src="$asset('/images/gateways/RAK7240/icon.svg')" v-svg-inject />
    <gro-button fill="clear" class="full-width">
      {{ $t('onboarding.gateway.type.types.RAK7240') }}
    </gro-button>
  </ion-row>
  <ion-row class="ion-padding ion-justify-content-center" @click="$emit('select',RAK7289)">
    <img :src="$asset('/images/gateways/RAK7289/icon.svg')" v-svg-inject />
    <gro-button fill="clear" class="full-width">
      {{ $t('onboarding.gateway.type.types.RAK7289') }}
    </gro-button>
  </ion-row>
  <ion-row class="ion-padding ion-justify-content-center" @click="$emit('select',LAIRD_RG1XX)">
    <img :src="$asset('/images/gateways/LAIRD_RG1XX/icon.svg')" v-svg-inject />
    <gro-button fill="clear" class="full-width">
      {{ $t('onboarding.gateway.type.types.LAIRD_RG1XX') }}
    </gro-button>
  </ion-row>
</template>

<script>
import { RAK7240, RAK7289, LAIRD_RG1XX } from '~gro-modules/Gateway/types'

export default {
  setup () {
    return {
      RAK7240,
      RAK7289,
      LAIRD_RG1XX,
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 50vw;
  width: auto;
  padding: 10px;
}
</style>
