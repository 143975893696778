<template>
  <ion-row class="ion-align-items-center ion-justify-content-center full-height">
    <div v-if="notUpdated && retries">
      <div class="setup-text">
        <h1 class="text-center">{{ $t('settings.gateway.update.Updating') }}</h1>
        <gro-spinner />
      </div>
    </div>
    <div v-else-if="notUpdated">
      <div class="setup-text">
        <h1 class="text-center">{{ $t('settings.gateway.update.Failed') }}</h1>
      </div>
      <gro-button @click="startUpdate">
        {{ $t('common.Retry') }}
      </gro-button>
    </div>
    <div v-else>
      <h1 class="text-center">{{ $t('settings.gateway.update.Success') }}</h1>
    </div>
  </ion-row>
</template>

<script>
import { gatewayQueries, gatewayMutations } from '~gro-modules/Gateway'
import { computed, ref, watch } from 'vue'
import gatewayTypes from '~gro-modules/Gateway/types'

export default {
  emits: ['update:notUpdated'],
  props: {
    type: {
      required: true,
      validator (value) {
        return gatewayTypes.includes(value)
      },
    },
    hardwareAddress: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const { gateway, refetch } = gatewayQueries.getGateway(props.hardwareAddress)
    const startFirmware = ref(null)
    const notUpdated = computed(() => {
      if (!startFirmware.value) return true
      return gateway.value.firmwareVersion === startFirmware.value
    })

    watch(gateway, newGateway => {
      if (!startFirmware.value) {
        startFirmware.value = newGateway.firmwareVersion
      }
    })

    watch(notUpdated, value => {
      emit('update:notUpdated', value)
    }, {
      immediate: true,
    })

    return {
      gateway,
      notUpdated,
      refetch,
      timeoutTimer: ref(null),
      retries: ref(60),
    }
  },
  methods: {
    async startUpdate () {
      this.retries = 200
      if (!await gatewayMutations.updateGatewayFirmware(this.hardwareAddress)) {
        this.retries = 0
        return
      }
      return this.pollFirmwareVersion()
    },
    async pollFirmwareVersion () {
      await this.$wait(3)
      await this.refetch()
      this.retries--
      if (this.retries) {
        return this.pollFirmwareVersion()
      }
    },
  },
  mounted () {
    this.startUpdate()
  },
  beforeUnmount () {
    this.retries = 0
  },
}
</script>
