import Singleton from './Singleton'

class Logger extends Singleton {
  constructor () {
    super(Logger, (instance) => {
      window.$log = instance.log
      instance.log('%cStarting debug logger', 'color: blue')
    })
  }

  log () {
    try {
      if (process.env.NODE_ENV !== 'development') return
      console.debug(...arguments)
    } catch (e) {
      // .. Ignore, logging can not cause errors
    }
  }
}

export default new Logger()
