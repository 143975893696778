<template>
  <ion-row class="ion-align-items-center ion-justify-content-center full-height">
    <div>
      <animated-svg :src="`/images/gateways/${type}/gateway_setup_searching.svg`"
                    height="59vw" />
      <div class="setup-text ion-text-center">
        <h1>{{ $t('settings.gateway.install.installing.Configuring') }}</h1>
        <gro-spinner />
      </div>
    </div>
  </ion-row>
</template>

<script>
import gatewayTypes from '~gro-modules/Gateway/types'
import { ref } from 'vue'
import { gatewayQueries, gatewayMutations, installStatus } from '~gro-modules/Gateway'

export default {
  emits: ['success', 'fail'],
  props: {
    type: {
      required: true,
      validator (value) {
        return gatewayTypes.includes(value)
      },
    },
    hardwareAddress: {
      type: String,
      required: true,
    },
  },
  setup () {
    return {
      timeoutTimer: ref(null),
      navigatedAway: ref(false),
      recoveryAttemptsLeft: ref(2),
    }
  },
  methods: {
    async pollInstallStatus () {
      await this.$wait(2)
      if (this.navigatedAway) {
        return
      }
      const status = await gatewayQueries.getInstallStatusAsync(this.hardwareAddress)
      if (status === installStatus.INSTALLED) {
        this.trackInstall(status)
        return this.$emit('success')
      } else if (status === installStatus.INSTALL_FAILED_RECOVERABLE && this.recoveryAttemptsLeft > 0) {
        this.recoveryAttemptsLeft--
        this.installStatus = installStatus.INSTALLING
        this.restartTimeout()
        await gatewayMutations.installCandidate(this.hardwareAddress, this.type)
      } else if (status === installStatus.INSTALLING) {
        this.trackInstall(status)
        return this.pollInstallStatus()
      } else {
        this.trackInstall(status)
        return this.$emit('fail', status)
      }
      return this.pollInstallStatus()
    },
    restartTimeout () {
      if (this.timeoutTimer) {
        clearTimeout(this.timeoutTimer)
      }
      this.timeoutTimer = setTimeout(() => {
        return this.$emit('fail', null)
      }, 900 * 1000)
    },
    trackInstall (status) {
      this.$track('GatewayInstall', {
        hardwareAddress: this.hardwareAddress,
        status,
      })
    },
  },
  mounted () {
    this.restartTimeout()
    this.pollInstallStatus()
  },

  beforeUnmount () {
    this.navigatedAway = true
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer)
    }
  },
}
</script>
