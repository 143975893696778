<template>
  <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
    <template v-if="isRunning">
      <h1 class="full-width">{{ $t('settings.convertor.fix.Running') }}</h1>
      <gro-spinner />
    </template>
    <template v-else-if="arpStatus === scannerStatus.ERROR">
      <h1>{{ $t('settings.convertor.fix.Scan error') }}</h1>
      <gro-button @click="rescan">
        {{ $t('common.Retry') }}
      </gro-button>
    </template>
    <template v-else-if="!convertorFound">
      <h1>{{ $t('settings.convertor.fix.Not found') }}</h1>
      <p>{{ $t('settings.convertor.fix.Not found explain') }}</p>
      <gro-button @click="rescan">
        {{ $t('common.Retry') }}
      </gro-button>
    </template>
    <template v-else-if="installFailed">
      <h1>{{ $t('settings.convertor.fix.Unable to recover') }}</h1>
      <gro-button @click="$emit('cancel')">
        {{ $t('common.Back') }}
      </gro-button>
    </template>
    <template v-else>
      <h1>{{ $t('settings.convertor.fix.Success') }}</h1>
    </template>
  </ion-row>
</template>

<script>

import { computed, ref, watch } from 'vue'
import { scannerStatus } from '~gro-modules/Arp'
import { convertorMutations, convertorQueries } from '~gro-modules/Convertor'
import moment from 'moment/moment'

export default {
  emits: ['update:isRunning', 'cancel'],
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const candidates = ref([])
    const installFailed = ref(false)
    const arpStatus = ref(scannerStatus.RUNNING)
    const {
      convertor,
      loading: loadingConvertor,
    } = convertorQueries.getConvertor(props.hardwareAddress, 2 * 1000)

    const convertorFound = computed(() => {
      return candidates.value.find(candidate => candidate.hardwareAddress === props.hardwareAddress)
    })

    const convertorReinstalled = computed(() => {
      return moment.utc().diff(convertor.value.installedAt, 'minutes') < 1
    })

    const isRunning = computed(() => {
      return loadingConvertor.value ||
        (arpStatus.value === scannerStatus.RUNNING && !convertorFound.value) ||
        (convertorFound.value && !installFailed.value && !convertorReinstalled.value)
    })

    watch(isRunning, value => {
      emit('update:isRunning', value)
    }, {
      immediate: true,
    })

    return {
      loadingConvertor,
      convertorFound,
      convertorReinstalled,
      isRunning,
      arpStatus,
      candidates,
      installFailed,
      convertor,
      scannerStatus,
    }
  },
  methods: {
    rescan () {
      this.arpStatus = scannerStatus.RUNNING
      convertorMutations.startScan()
      this.checkArpStatus()
    },
    async checkArpStatus () {
      await this.$wait(5)
      const { status, candidates } = await convertorQueries.getScanStatus(true, this.hardwareAddress)
      this.arpStatus = status
      this.candidates = candidates
      if (this.convertorFound) {
        return this.reinstall()
      } else if (this.arpStatus === scannerStatus.RUNNING) {
        return this.checkArpStatus()
      }
    },
    async reinstall () {
      await convertorMutations.installConvertor({
        name: this.convertor.name,
        hardwareAddress: this.hardwareAddress,
        groupUuid: this.convertor.group.uuid,
        ipAddress: this.convertorFound.ipAddress,
      })
      await this.$wait(20)
      if (!this.convertorReinstalled) {
        this.installFailed = true
      }
    },
  },
  mounted () {
    convertorMutations.startScan()
    this.checkArpStatus()
  },
}
</script>
