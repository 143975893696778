<template>
  <ion-row class="ion-align-items-center ion-justify-content-center full-height">
    <div class="full-width ion-text-center">
      <div class="setup-text ion-text-center">
        <div class="setup-text ion-text-center">
          <template v-if="status === STATE_INIT">
            <img :src="$asset('/images/smartbox/smartbox_setup.svg')" v-svg-inject />
            <h1>{{ $t('settings.smartbox.Add smartbox') }}</h1>
          </template>
          <template v-else-if="status === STATE_SAVING">
            <img :src="$asset('/images/smartbox/smartbox_setup_searching.svg')" v-svg-inject />
            <h1>{{ $t('settings.smartbox.Add smartbox') }}</h1>
          </template>
          <template v-else-if="status === STATE_ERROR">
            <img :src="$asset('/images/smartbox/smartbox_setup_none.svg')" v-svg-inject />
            <h1>{{ $t('settings.smartbox.Add smartbox') }}</h1>
          </template>
        </div>
      </div>
      <ion-item class="padding ion-margin-top">
        <ion-label position="stacked">{{ $t('settings.smartbox.IP address') }}</ion-label>
        <ion-input v-model="form.ipAddress"
                   class="input-devicegroup" />
      </ion-item>
      <ion-item class="padding ion-margin-top">
        <ion-label position="stacked">{{ $t('settings.smartbox.Name') }}</ion-label>
        <ion-input v-model=form.name
                   class="input-devicegroup"
                   :placeholder="$t('settings.smartbox.Name placeholder')"
                   :maxlength="20" />
      </ion-item>
    </div>
  </ion-row>
</template>

<script>

import { smartboxMutations } from '~gro-modules/Smartbox'

const STATE_INIT = 0
const STATE_SAVING = 1
const STATE_ERROR = 2

export default {
  data () {
    return {
      status: STATE_INIT,
      form: {
        ipAddress: '',
        name: '',
      },
      STATE_INIT,
      STATE_SAVING,
      STATE_ERROR,
    }
  },
  methods: {
    async submit () {
      this.status = STATE_SAVING
      try {
        const response = await smartboxMutations.addSmartbox(this.form)
        if (!response.errors) {
          return true
        }
        this.$showGraphQLErrors(response.errors)
      } catch (e) {
        this.$toast('common.Error')
        console.error(e)
      }
      this.status = STATE_ERROR
      return false
    },
  },
}
</script>
