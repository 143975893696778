import { reactive } from 'vue'

export function formCopy (onResult, callback) {
  const form = reactive(callback())

  onResult(() => {
    const data = callback()
    Object.keys(data).forEach(field => {
      form[field] = data[field]
    })
  })

  return form
}
