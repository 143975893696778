import Singleton from './Singleton'

class MiddlewareHelper extends Singleton {
  constructor () {
    super(MiddlewareHelper, function (instance) {
      instance._hooks = {
        before: {},
        after: {},
      }
      instance._router = null
    })
  }

  initialize (router) {
    this._router = router
    router.beforeEach(this.applyBeforeHooks.bind(this))
    router.afterEach(this.applyAfterHooks.bind(this))
  }

  add (name, beforeCallback, afterCallback = null) {
    if (beforeCallback) {
      this._hooks.before[name] = beforeCallback
    }
    if (afterCallback) {
      this._hooks.after[name] = afterCallback
    }
  }

  async applyBeforeHooks (to, from, next) {
    for (const hook of this.getEnabledMiddleware('before', to.matched)) {
      const redirect = await this._hooks.before[hook]({ to, from })
      if (redirect) {
        console.error(`Middleware failed: [${hook}] on [${to.name}]`)
        next(redirect)
        return
      }
    }
    next()
  }

  async applyAfterHooks (to, from) {
    for (const hook of this.getEnabledMiddleware('after', to.matched)) {
      const redirect = await this._hooks.after[hook]({ to, from })
      if (redirect) {
        console.error(`Middleware failed: [${hook}] on [${to.name}]`)
        this._router.push(redirect)
        return
      }
    }
  }

  getEnabledMiddleware (group, matched = []) {
    const names = Object.keys(this._hooks[group])
    return names.filter(name => !matched.some(route => route.meta[name] === false))
  }
}

export default new MiddlewareHelper()
