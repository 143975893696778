<template>
  <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
    <template v-if="isRunning">
      <h1 class="full-width">{{ $t('settings.gateway.fix.Running') }}</h1>
      <gro-spinner />
    </template>
    <template v-else-if="arpStatus === scannerStatus.ERROR">
      <h1>{{ $t('settings.gateway.fix.Scan error') }}</h1>
      <gro-button @click="rescan">
        {{ $t('common.Retry') }}
      </gro-button>
    </template>
    <template v-else-if="!gatewayFound">
      <h1>{{ $t('settings.gateway.fix.Not found') }}</h1>
      <p>{{ $t('settings.gateway.fix.Not found explain') }}</p>
      <gro-button @click="rescan">
        {{ $t('common.Retry') }}
      </gro-button>
    </template>
    <template v-else-if="reinstallStatus === installStatus.REINSTALL_FAILED">
      <h1>{{ $t('settings.gateway.fix.Unable to recover') }}</h1>
      <gro-button @click="$goBack">
        {{ $t('common.Back') }}
      </gro-button>
    </template>
    <template v-else-if="reinstallStatus === installStatus.INSTALL_FAILED_UNAUTHENTICATED">
      <h1>{{ $t('settings.gateway.fix.Unable to recover without reset') }}</h1>
      <gro-button @click="$goBack">
        {{ $t('common.Back') }}
      </gro-button>
    </template>
    <template v-else>
      <h1>{{ $t('settings.gateway.fix.Success') }}</h1>
    </template>
  </ion-row>
</template>

<script>

import { ref, computed, watch } from 'vue'
import { scannerStatus } from '~gro-modules/Arp'
import { gatewayMutations, gatewayQueries, installStatus } from '~gro-modules/Gateway'
import moment from 'moment/moment'

export default {
  emits: ['update:isRunning', 'cancel'],
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const candidates = ref([])
    const {
      gateway,
      loading,
    } = gatewayQueries.getGateway(props.hardwareAddress, 2 * 1000)
    const arpStatus = ref(scannerStatus.RUNNING)
    const reinstallStatus = ref(installStatus.INSTALLING)
    const recoveryAttemptsLeft = ref(2)

    const gatewayFound = computed(() => {
      return candidates.value.find(candidate => candidate.hardwareAddress === props.hardwareAddress)
    })

    const gatewayReinstalled = computed(() => {
      return moment.utc().diff(gateway.value.installedAt, 'minutes') < 1
    })

    const isRunning = computed(() => {
      return loading.value ||
        arpStatus.value === scannerStatus.RUNNING ||
        (
          gatewayFound.value && (
            reinstallStatus.value === installStatus.INSTALLING ||
            reinstallStatus.value === installStatus.INSTALL_FAILED_RECOVERABLE ||
            (reinstallStatus.value === installStatus.INSTALLED && !gatewayReinstalled.value)
          )
        )
    })

    watch(isRunning, value => {
      emit('update:isRunning', value)
    }, {
      immediate: true,
    })

    return {
      arpStatus,
      reinstallStatus,
      recoveryAttemptsLeft,
      candidates,
      gateway,
      firstScanStarted: ref(false),
      scannerStatus,
      installStatus,
      gatewayFound,
      isRunning,
    }
  },
  watch: {
    gateway: {
      handler (gateway) {
        if (!gateway?.type || this.firstScanStarted || this.gatewayFound) return
        this.firstScanStarted = true
        gatewayMutations.startScan(gateway.type)
        this.checkArpStatus()
      },
      immediate: true,
    },
  },
  methods: {
    rescan () {
      if (this.arpStatus === scannerStatus.RUNNING) {
        return
      }
      this.arpStatus = scannerStatus.RUNNING
      gatewayMutations.startScan(this.gateway.type)
      this.checkArpStatus()
    },
    async checkArpStatus () {
      await this.$wait(5)
      const { status, candidates } = await gatewayQueries.getScanStatus(this.hardwareAddress)
      this.arpStatus = status
      this.candidates = candidates
      if (this.gatewayFound) {
        this.arpStatus = scannerStatus.DONE
        return this.reinstall()
      } else if (this.arpStatus === scannerStatus.RUNNING) {
        await this.$wait(5)
        return this.checkArpStatus()
      }
    },
    async reinstall () {
      await gatewayMutations.reinstallGateway(this.gateway.hardwareAddress)
      this.checkInstallStatus()
    },
    async checkInstallStatus () {
      await this.$wait(5)
      this.reinstallStatus = await gatewayQueries.getInstallStatusAsync(this.hardwareAddress)

      if (this.reinstallStatus === installStatus.INSTALLED) {
        return
      }
      if (this.reinstallStatus === installStatus.REINSTALL_FAILED) {
        return
      }
      if (this.reinstallStatus === installStatus.REINSTALL_FAILED_RECOVERABLE) {
        if (this.recoveryAttemptsLeft === 0) {
          this.reinstallStatus = installStatus.REINSTALL_FAILED
          return
        }
        this.recoveryAttemptsLeft--
        return this.reinstall()
      }
      return this.checkInstallStatus()
    },
  },
}
</script>
