<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div v-else>
    <ion-item>
      <ion-label position="stacked">{{ $t('convertor.Name') }}</ion-label>
      <ion-input class="input-devicegroup"
                 v-model="form.name" />
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ $t('convertor.Hardware address') }}</ion-label>
      <ion-input class="input-devicegroup"
                 v-model="form.hardwareAddress"
                 readonly />
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ $t('convertor.IP address') }}</ion-label>
      <ion-input class="input-devicegroup"
                 v-model="convertor.ipAddress"
                 readonly />
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ $t('deviceGroup.Singular') }}</ion-label>
      <ion-select v-model="form.groupUuid"
                  :placeholder="$t('convertor.Group placeholder')"
                  interface="alert"
                  :interfaceOptions="{cssClass: 'alert-room-select' }">
        <ion-select-option v-for="group in groups" :key="group.uuid" :value="group.uuid">
          {{ group.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item v-if="!form.groupUuid" lines="none" class="group-binding-alert">
      {{ $t('convertor.Not connected to group') }}
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{ $t('convertor.Interval minutes') }}</ion-label>
      <ion-input class="input-devicegroup"
                 type="number"
                 min="1"
                 v-model="form.updateIntervalMinutes" />
    </ion-item>
    <ion-item v-if="convertor">
      <ion-label>{{ $t('settings.convertor.Last seen') }}</ion-label>
      <ion-label class="ion-text-wrap">
        <connection-last-seen
          @update:hasProblem="$emit('update:hasConnectionProblem',$event)"
          :lastSeen="convertor.lastSeen"
          :createdAt="convertor.installedAt"
          :hasData="true"
          :firstConnectionMinutes="convertor.updateIntervalMinutes * 2"
          details />
      </ion-label>
    </ion-item>
  </div>
</template>

<script>
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { convertorQueries, convertorMutations } from '~gro-modules/Convertor'
import { formCopy } from '~gro-helpers'
import { watch } from 'vue'

export default {
  props: ['hardwareAddress'],
  emits: ['update:convertor', 'update:loading', 'update:hasConnectionProblem'],
  setup (props, { emit }) {
    const { groups } = deviceGroupQueries.getAll('uuid', 'name')
    const { convertor, loading, onResult, refetch } = convertorQueries.getConvertor(props.hardwareAddress, 5 * 1000)
    const form = formCopy(onResult, () => {
      emit('update:convertor', convertor)
      return {
        name: convertor.value.name,
        hardwareAddress: convertor.value.hardwareAddress,
        updateIntervalMinutes: convertor.value.updateIntervalMinutes,
        groupUuid: convertor.value.group ? convertor.value.group.uuid : null,
      }
    })

    watch(loading, (value) => {
      emit('update:loading', value)
    }, { immediate: true })

    return {
      convertor,
      groups,
      loading,
      refetch,
      form,
    }
  },
  methods: {
    async submit () {
      const result = await convertorMutations.updateConvertor(this.form)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackChanged()
      this.refetch()
      return true
    },
    async deleteConvertor () {
      if (!await this.$confirm('convertor.Delete confirm')) return false
      const result = await convertorMutations.deleteConvertor(this.hardwareAddress)
      if (result.errors) {
        await this.$showGraphQLErrors(result.errors)
        return false
      }
      this.trackDeleted()
      return true
    },
    trackChanged () {
      this.$track('ConvertorChanged', this.form)
    },
    trackDeleted () {
      this.$track('ConvertorDeleted', {
        hardwareAddress: this.hardwareAddress,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.group-binding-alert {
  color: var(--ion-color-danger);
  font-size: 12px;
  font-style: italic;
}
</style>
