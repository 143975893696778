import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import get from 'get-value'

/**
 * A simplifier function to create a simple (one dimensional) graphql query and return the requested fields
 *
 * @param path
 * @param fields
 * @param idValue
 * @param idField
 * @returns {*}
 */
export function itemQuery (path = '', fields = [], idValue = null, idField = 'uuid') {
  const { result, loading, refetch, onResult } = fieldsQuery(path, fields, idValue, idField)
  return {
    _meta: { loading, refetch, onResult },
    ...fields.reduce((data, field) => {
      data[field] = useResult(
        result,
        null,
        (data) => get(data, `${path}.${field}`, { default: [] }),
      )
      return data
    }, {}),
  }
}

/**
 * A simplifier function to create a simple graphql query and return the requested collection
 *
 * @param collectionName
 * @param path
 * @param fields
 * @param idField
 * @returns {*}
 */
export function collectionQuery (collectionName = '', path = '', fields = [], idField = 'uuid') {
  const { result, loading, refetch, onResult } = fieldsQuery(path, fields, null, idField)
  return {
    _meta: { loading, refetch, onResult },
    [collectionName]: useResult(result, [], (data) => get(data, path, { default: [] })),
  }
}

/**
 * A simplifier function execute a simple graphql query to select fields on a single graphQL path
 *
 * @param path
 * @param fields
 * @param idValue
 * @param idField
 * @returns {*}
 */
export function fieldsQuery (path = '', fields = [], idValue = null, idField = 'uuid') {
  const variables = idValue ? { [idField]: idValue } : {}
  const segments = path.split('.').reverse()
  const name = segments.reduce((name, segment) => {
    if (name) return name + segment.charAt(0).toUpperCase() + segment.slice(1)
    return segment
  }, '')
  let queryString = segments.reduce((query, segment, index) => {
    if (!index) {
      if (idField) {
        query = `_id: ${idField}\n`
      }
      query += `${fields.map(field => `${field}\n`)}`
      if (idValue) {
        return `${segment}(${idField}:$${idField}) { \n ${query} \n }`
      }
    }
    return `${segment} { \n ${query} \n }`
  }, '')

  if (idValue) {
    queryString = `query ${name}Single ($${idField}: ID!){ ${queryString} }`
  } else {
    queryString = `query ${name}All {${queryString}}`
  }
  return useQuery(gql`${queryString}`, variables)
}
