<template>
  <ion-row class="ion-align-items-center ion-justify-content-top full-height">
    <div class="full-width ion-text-center">
      <gro-spinner v-if="loading" />
      <div v-else>
        <ion-item class="ion-no-padding">
          <ion-label position="stacked">{{ $t('settings.convertor.detect.Select group') }}</ion-label>
          <ion-select v-model="form.groupUuid"
                      :placeholder="$t('convertor.Group placeholder')"
                      interface="alert"
                      :interfaceOptions="{cssClass: 'alert-room-select' }">
            <ion-select-option v-for="group in groups" :key="group.uuid" :value="group.uuid">
              {{ group.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item class="ion-no-padding ion-margin-top">
          <ion-label position="stacked">{{ $t('convertor.Name') }}</ion-label>
          <ion-input class="input-devicegroup"
                     v-model="form.name" />
        </ion-item>
      </div>
    </div>
  </ion-row>
</template>

<script>
import { deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { convertorQueries, convertorMutations } from '~gro-modules/Convertor'

export default {
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      default: () => ({
        groupUuid: null,
        name: '',
      }),
    },
  },
  setup () {
    const { _meta, groups } = deviceGroupQueries.getAll('uuid', 'name')
    return {
      loading: _meta.loading,
      groups,
    }
  },
  methods: {
    async install () {
      const { candidates } = await convertorQueries.getScanStatus(false)
      const candidate = candidates.find(candidate => candidate.hardwareAddress === this.hardwareAddress)
      if (!candidate) {
        return false
      }
      return await convertorMutations.installConvertor({ ...candidate, ...this.form })
    },
  },
}
</script>
