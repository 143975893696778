<template>
  <ion-row class="ion-align-items-center ion-justify-content-center full-height">
    <div class="full-width ion-text-center">
      <template v-if="status === scannerStatus.RUNNING">
        <animated-svg src="/images/converter/converter_setup_searching.svg"
                      height="57vw" />
        <div class="setup-text">
          <h1>{{ $t('settings.convertor.detect.Searching') }}</h1>
        </div>
      </template>
      <template v-else-if="status === scannerStatus.ERROR">
        <div class="setup-text">
          <animated-svg src="/images/converter/converter_setup_none.svg" height="57vw" />
          <h1>{{ $t('settings.convertor.detect.Error') }}</h1>
          <gro-button class="ion-margin-top"
                      @click="search"
                      expand="full">
            {{ $t('settings.convertor.detect.Retry') }}
          </gro-button>
        </div>
      </template>
      <template v-else-if="candidates.length">
        <div class="setup-text ion-text-center">
          <img :src="$asset('/images/converter/converter_setup_found.svg')" v-svg-inject />
          <h1>{{ $t('settings.convertor.detect.Select convertor') }}</h1>
        </div>
      </template>
      <template v-else>
        <img :src="$asset('/images/bridge_setup_select_bridge_none.svg')" v-svg-inject />
        <div class="setup-text">
          <h1>{{ $t('settings.convertor.detect.No convertors') }}</h1>
          <ion-text color="medium">
            <p>{{ $t('settings.convertor.detect.Check before retry') }}</p>
          </ion-text>
          <gro-button class="ion-margin-top"
                      @click="search"
                      expand="full">
            {{ $t('settings.convertor.detect.Retry') }}
          </gro-button>
        </div>
      </template>
      <ion-list v-if="candidates.length" class="persistent-scrollbar" style="max-height: 40vh;">
        <div v-for="candidate in candidates" :key="candidate.hardwareAddress">
          <gro-button class="ion-margin-top"
                      expand="full"
                      @click="$emit('selected', candidate)">
            {{ candidate.hardwareAddress || $t('common.Unknown') }}
          </gro-button>
        </div>
        <gro-button v-if="status !== scannerStatus.RUNNING"
                    color="light"
                    expand="full"
                    class="ion-margin-top"
                    @click="search">
          {{ $t('settings.convertor.detect.Retry') }}
        </gro-button>
      </ion-list>
    </div>
  </ion-row>

</template>

<script>
import { scannerStatus } from '~gro-modules/Arp'
import { convertorQueries, convertorMutations } from '~gro-modules/Convertor'

export default {
  data () {
    return {
      redirected: false,
      candidates: [],
      status: scannerStatus.RUNNING,
      scannerStatus,
    }
  },
  emits: ['selected'],
  methods: {
    async search () {
      this.status = scannerStatus.RUNNING
      await convertorMutations.startScan()
      return this.checkStatus()
    },
    async checkStatus () {
      await this.$wait(5)
      const { status, candidates } = await convertorQueries.getScanStatus()
      this.status = status
      this.candidates = candidates

      if (this.status === scannerStatus.RUNNING) this.checkStatus()
    },
  },
  mounted () {
    this.search()
  },
}
</script>
